import React from 'react'
import PropTypes from 'prop-types'

import AccountInfo from './AccountInfo'
import AvatarFlyoutLogout from './AvatarFlyoutLogout'
import LinkGroup from './LinkGroup'

import Styles from './styles.scss'

import useFilterLinksByPolicy from './hooks/useFilterLinksByPolicy'

import { LINK_GROUPS } from './linksConfig'


export default function AvatarFlyout({ toggle }) {
  const linkGroups = useFilterLinksByPolicy(LINK_GROUPS)

  return (
    <div
      className={ Styles.root }
      onClick={ onRootClick }
    >
      <div className={ Styles.content }>
        <div className={ Styles.accountInfo }>
          <AccountInfo
            handleClick={ toggle }
          />
        </div>

        <div className={ Styles.linkGroups }>
          {
            linkGroups.map(({ links: linkGroup, title }, i) => (
              <LinkGroup
                className={ Styles.linkGroup }
                key={ i }
                linkGroup={ linkGroup }
                title={ title }
              />
            ))
          }
        </div>

        <AvatarFlyoutLogout />
      </div>
    </div>
  )
}

AvatarFlyout.propTypes = {
  toggle: PropTypes.func.isRequired,
}

function onRootClick(e) {
  e.stopPropagation()
}

import * as Sentry from '@sentry/react'

import SentryKeys from './sentry-keys'
import configureSentryScope from './configureSentryScope'


export default function initializeSentry({ store }) {
  // eslint-disable-next-line no-process-env
  const { NODE_ENV } = process.env

  // Traffic to Sentry costs the company money, so we'll not generate
  // unnecessary expenditures here.
  if ( NODE_ENV === 'development' ) {
    return
  }

  const dsn = SentryKeys[NODE_ENV] || SentryKeys.staging

  Sentry.init({
    dsn,
    integrations: [
      Sentry.replayIntegration(),
    ],
    maskAllText: false,
    release: '2.0.0',

    // Session Replay (https://docs.sentry.io/platforms/javascript/guides/react/session-replay/)
    //
    // We don't want to record any sessions unless they're throwing errors.
    replaysSessionSampleRate: 0,

    // We want to capture 100% of the sessions generating errors
    replaysOnErrorSampleRate: 1.0,
  })

  Sentry.getCurrentScope(configureSentryScope(store))
}

import convertWebsitesToOptions from '../../lib/convertWebsitesToOptions'

import buildWebsites from './buildWebsites'


export default function makeLoadWebsiteSelectOptions({ defaultValue, handlePagination, mutateAsync }) {
  return async (filter, registerReactSelectOptions) => {
    try {
      const {
        pagination,
        websites,
      } = await mutateAsync(filter)

      handlePagination(pagination)

      const _websites = buildWebsites({
        addValueToOptions: !filter,
        value: defaultValue,
        websites,
      })

      const options = convertWebsitesToOptions(_websites)

      // Because we're using this function with throttle
      registerReactSelectOptions(options)
    } catch (error) {
      registerReactSelectOptions()
    }
  }
}

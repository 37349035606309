import React from 'react'
import PropTypes from 'prop-types'

import UserTrackerInnerProvider from './UserTrackerInnerProvider'
import UserflowAPIProvider from './UserflowAPIProvider'
import ZendeskAPIProvider from './ZendeskAPIProvider'


export default function UserTrackerProvider({ children }) {
  return (
    <UserflowAPIProvider>
      <ZendeskAPIProvider>
        <UserTrackerInnerProvider>
          { children }
        </UserTrackerInnerProvider>
      </ZendeskAPIProvider>
    </UserflowAPIProvider>
  )
}

UserTrackerProvider.propTypes = {
  children: PropTypes.node,
}

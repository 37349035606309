import React, {
  useMemo,
} from 'react'

import PropTypes from 'prop-types'

import UserTrackerContext from '../UserTrackerContext'

import { useUserflowAPI } from '../UserflowAPIProvider'
import { useZendeskAPI } from '../ZendeskAPIProvider'

import makeTrackerAPI from './lib/makeTrackerAPI'


export default function UserTrackerInnerProvider({ children }) {
  const userflow = useUserflowAPI()
  const zendesk = useZendeskAPI()

  const value = useMemo(() => {
    return makeTrackerAPI({
      observers: [
        userflow,
        zendesk,
      ],
    })
  }, [userflow, zendesk])

  return (
    <UserTrackerContext.Provider
      value={ value }
    >
      { children }
    </UserTrackerContext.Provider>
  )
}

UserTrackerInnerProvider.propTypes = {
  children: PropTypes.node,
}

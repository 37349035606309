import Locale from './locale'

const DOMAIN = 'termly.io'

// NOTE: These values are not aribtrary...they correspond to the `type` field
// that we get from the backend.
const ACCEPTABLE_USE_POLICY = 'AcceptableUsePolicy'
const COOKIE = 'Cookie'
const DISCLAIMER = 'Disclaimer'
const EULA = 'EULA'
const IMPRESSUM = 'Impressum'
const PRIVACY = 'Privacy'
const RETURN = 'Return'
const SHIPPING_POLICY = 'Shipping'
const TERMS = 'Terms'

const UNEDITABLE_POLICY_TYPES = new Set([
  COOKIE
])

/* eslint-disable global-require */
const POLICY_CONFIGS = {
  [ACCEPTABLE_USE_POLICY]: {
    trackingEventName: 'hasAcceptableUsePolicy',
    dataTracking: 'click-new-acceptable-use',
    generatorLink: makeGeneratorLink('acceptable-use-policy-generator'),
    icon: require('./img/acceptable-use-icon.svg'),
    id: 'acceptableUsePolicy',
    name: Locale.acceptableUsePolicy,
    priority: 7,
    slug: 'acceptable-use',
    tip: Locale.acceptableUsePolicyTip,
  },
  [COOKIE]: {
    trackingEventName: void 0,
    dataTracking: void 0,
    generatorLink: makeGeneratorLink('cookie-consent-manager'),
    icon: require('./img/cookie-icon.svg'),
    id: 'cookiePolicy',
    name: Locale.cookiePolicy,
    priority: 2,
    slug: 'cookie-policy',
    tip: Locale.cookiePolicyTip,
  },
  [DISCLAIMER]: {
    trackingEventName: 'hasPublishedDisclaimer',
    dataTracking: 'click-new-disclaimer',
    generatorLink: makeGeneratorLink('disclaimer-generator'),
    icon: require('./img/disclaimer-icon.svg'),
    id: 'disclaimer',
    name: Locale.disclaimer,
    priority: 5,
    slug: 'disclaimer',
    tip: Locale.disclaimerTip,
  },
  [EULA]: {
    trackingEventName: 'hasEULA',
    dataTracking: 'click-new-eula',
    generatorLink: makeGeneratorLink('eula-generator'),
    icon: require('./img/eula-icon.svg'),
    id: 'eula',
    name: Locale.eula,
    priority: 3,
    slug: 'eula',
    tip: Locale.eulaTip,
  },
  [IMPRESSUM]: {
    trackingEventName: 'hasImpressum',
    dataTracking: 'click-new-impressum',
    generatorLink: makeGeneratorLink('impressum-generator'),
    icon: require('./img/impressum-icon.svg'),
    id: 'impressum',
    name: Locale.impressum,
    priority: 8,
    slug: 'impressum',
    tip: Locale.impressumTip,
  },
  [PRIVACY]: {
    trackingEventName: 'hasPublishedPrivacyPolicy',
    dataTracking: 'click-new-privacy-policy',
    generatorLink: makeGeneratorLink('privacy-policy-generator'),
    icon: require('./img/privacy-icon.svg'),
    id: 'privacyPolicy',
    name: Locale.privacyPolicy,
    priority: 1,
    slug: 'privacy-policy',
    tip: Locale.privacyPolicyTip,
  },
  [RETURN]: {
    trackingEventName: 'hasPublishedReturnPolicy',
    dataTracking: 'click-new-return-policy',
    generatorLink: makeGeneratorLink('refund-return-policy-generator'),
    icon: require('./img/return-icon.svg'),
    id: 'refundPolicy',
    name: Locale.returnPolicy,
    priority: 4,
    slug: 'refund-policy',
    tip: Locale.returnPolicyTip,
  },
  [SHIPPING_POLICY]: {
    trackingEventName: 'hasPublishedShippingPolicy',
    dataTracking: 'click-new-shipping-policy',
    generatorLink: makeGeneratorLink('shipping-policy-generator'),
    icon: require('./img/shipping-policy-icon.svg'),
    id: 'shippingPolicy',
    name: Locale.shippingPolicy,
    priority: 6,
    slug: 'shipping-policy',
    tip: Locale.shippingPolicyTip,
  },
  [TERMS]: {
    trackingEventName: 'hasPublishedTermsofUse',
    dataTracking: 'click-new-terms-of-use',
    generatorLink: makeGeneratorLink('terms-and-conditions-generator'),
    icon: require('./img/terms-icon.svg'),
    id: 'termsOfService',
    name: Locale.termsAndConditions,
    priority: 2,
    slug: 'terms-of-service',
    tip: Locale.termsAndConditionsTip,
  },
}
/* eslint-enable */

const ALL_TYPES = Object.keys(POLICY_CONFIGS)

export default {
  ACCEPTABLE_USE_POLICY,
  COOKIE,
  DISCLAIMER,
  EULA,
  IMPRESSUM,
  PRIVACY,
  RETURN,
  SHIPPING_POLICY,
  TERMS,

  get allTypes() {
    return ALL_TYPES
  },
  get defaultPolicy() {
    return PRIVACY
  },

  hasCustomizedPolicyEntryPoint(policyType) {
    return policyType === COOKIE
  },

  hasPolicyMigratorFunction(policyType) {
    return policyType !== COOKIE
  },

  isEditablePolicyType(policyType) {
    if ( !policyType ) {
      return false
    }

    return !UNEDITABLE_POLICY_TYPES.has(policyType)
  },

  getSlugForPolicyType(policyType) {
    return POLICY_CONFIGS[policyType]?.slug
  },

  getPolicyTypeForSlug(desiredSlug) {
    if ( !this.typeToSlug ) {
      this.typeToSlug = Object.entries(POLICY_CONFIGS)
        .reduce((collector, [policyType, { slug }]) => ({
          ...collector,
          [slug]: policyType,
        }), {})
    }

    return this.typeToSlug[desiredSlug]
  },

  getPriorityForPolicyType(policyType) {
    return POLICY_CONFIGS[policyType]?.priority
  },

  getAllTypesByPriority() {
    return ALL_TYPES.sort(compareByPriority)
  },

  getTrackingEventNameForPolicyType(policyType) {
    return POLICY_CONFIGS[policyType]?.trackingEventName
  },

  getDataTrackingForPolicyType(policyType) {
    return POLICY_CONFIGS[policyType]?.dataTracking
  },

  getGeneratorLink(policyType) {
    return POLICY_CONFIGS[policyType]?.generatorLink
  },

  getIconSrc(policyType) {
    return POLICY_CONFIGS[policyType]?.icon
  },

  getID(policyType) {
    return POLICY_CONFIGS[policyType]?.id
  },

  getNameMessage(policyType) {
    return POLICY_CONFIGS[policyType]?.name
  },

  getTipMessage(policyType) {
    return POLICY_CONFIGS[policyType]?.tip
  }
}

function compareByPriority(firstPolicyType, secondPolicyType) {
  return POLICY_CONFIGS[firstPolicyType].priority - POLICY_CONFIGS[secondPolicyType].priority
}

function makeGeneratorLink(slug) {
  return `https://${ DOMAIN }/products/${ slug }/`
}

export const _private = {
  POLICY_CONFIGS,
}

import { defineMessages } from 'react-intl'


export default defineMessages({
  acceptableUsePolicy: {
    id: 'policy-types.acceptable-use-policy',
    defaultMessage: 'Acceptable Use Policy',
  },
  acceptableUsePolicyTip: {
    id: 'policy-types.acceptable-use-policy-tip',
    defaultMessage: 'An acceptable use policy supplements your legal Terms to describe how people must engage with your platform. This policy protects your business, reduces the risk of misuse and enables a better experience for your users.',
  },

  cookiePolicy: {
    id: 'policy-types.cookie-policy',
    defaultMessage: 'Cookie Policy',
  },
  cookiePolicyTip: {
    id: 'policy-types.cookie-policy-tip',
    defaultMessage: 'A cookie policy describes the types of cookies your website or app uses, why you use cookies, and tells users how they can set cookie preferences.',
  },

  disclaimer: {
    id: 'policy-types.disclaimer',
    defaultMessage: 'Disclaimer',
  },
  disclaimerTip: {
    id: 'policy-types.disclaimer-tip',
    defaultMessage: 'If your business offers advice, products, or services to users, then you should have a disclaimer to protect yourself from legal liability.',
  },

  eula: {
    id: 'policy-types.eula',
    defaultMessage: 'EULA',
  },
  eulaTip: {
    id: 'policy-types.eula-tip',
    defaultMessage: 'An End User License Agreement (EULA) outlines the rules and restrictions for software use. Your users must accept your EULA before downloading and using your app.',
  },

  impressum: {
    id: 'policy-types.impressum',
    defaultMessage: 'Impressum',
  },
  impressumTip: {
    id: 'policy-types.impressum-tip',
    defaultMessage: 'An Impressum is a statement of authorship and ownership over your content, required for businesses with an online presence in Germany, Austria, or Switzerland.',
  },

  privacyPolicy: {
    id: 'policy-types.privacy-policy',
    defaultMessage: 'Privacy Policy',
  },
  privacyPolicyTip: {
    id: 'policy-types.privacy-policy-tip',
    defaultMessage: 'A privacy policy states how your website or app collects, uses, and shares visitor’s personal information. Most websites and apps are legally required to have a privacy policy.',
  },


  returnPolicy: {
    id: 'policy-types.return-policy',
    defaultMessage: 'Return Policy',
  },
  returnPolicyTip: {
    id: 'policy-types.return-policy-tip',
    defaultMessage: 'A return policy helps set expectations with your customers by stating how and when goods and services can be returned.',
  },


  shippingPolicy: {
    id: 'policy-types.shipping-policy',
    defaultMessage: 'Shipping Policy',
  },
  shippingPolicyTip: {
    id: 'policy-types.shipping-policy-tip',
    defaultMessage: 'A shipping policy is essential if your business sells goods online. It outlines important information like shipping fees, methods, and restrictions.',
  },


  termsAndConditions: {
    id: 'policy-types.tandc',
    defaultMessage: 'Terms and Conditions',
  },
  termsAndConditionsTip: {
    id: 'policy-types.tandc-tip',
    defaultMessage: 'Terms and conditions protect your business by preventing inappropriate user behavior, limiting your liability, and protecting your intellectual property.',
  },
})
